import "./App.css";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div className="App">
      <h3>First Page</h3>
      {/* <Navbar /> */}
      <Navbar />
      <Box
        component="img"
        // src='https://blog.depositphotos.com/wp-content/uploads/2017/07/Soothing-nature-backgrounds-2.jpg'
        src="https://hips.hearstapps.com/hmg-prod/images/gettyimages-142061241-1536098801.jpg?resize=2048:*"
      ></Box>
    </div>
  );
}

export default App;
