import logo from "../images/logo.svg";
import { Icon } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          {/* <img src={logo} className="nav-logo" alt="backroads" /> */}
          <ul className="nav-icons">
            <SchoolIcon />
            {/* {socialLinks.map((link) => {
              return (
                <SocialLink key={link.id} {...link} itemClass="nav-icon" />
              );
            })} */}
          </ul>
          {/* <button type="button" className="nav-toggle" id="nav-toggle">
            <i className="fas fa-bars"></i>
          </button> */}
        </div>
        {/* <!-- left this comment on purpose --> */}
        <ul className="nav-links" id="nav-links">
          <li>
            <a className="nav-link"> Page One </a>
          </li>
          <li>
            <a className="nav-link"> Page Two </a>
          </li>
          <li>
            <a className="nav-link"> Page Three </a>
          </li>
          <li>
            <a className="nav-link"> Page Foour </a>
          </li>
        </ul>

        <ul className="nav-icons">
          {/* {socialLinks.map((link) => {
            return <SocialLink key={link.id} {...link} itemClass="nav-icon" />;
          })} */}
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;
